.App {
    text-align: center;
}


.App-link {
    color: #61dafb;
}


#loader {
    /*background: #fff;*/
    color: transparent;
    /*position: absolute;*/
    /*top: 0;
    bottom: 0;
    right: 0;
    left: 0;*/
    /*text-indent: -9999px;*/
    z-index: -1;
    margin-top: 10%;
}

#logo-loader {
    /*position: absolute;*/
    transform-style: preserve-3d;
    transform: rotateY(0deg);
    opacity: 1;
    /*top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -100px;*/
    height: 200px;
    width: 200px;
}

@keyframes gen-animation-keyframes {
    0% {
        transform: rotateY(0deg);
        opacity: 1;
        animation-timing-function: linear;
    }

    25% {
        transform: rotateY(-90deg);
        opacity: 1;
        animation-timing-function: linear;
    }

    50% {
        transform: rotateY(-180deg);
        opacity: 1;
        animation-timing-function: linear;
    }

    75% {
        transform: rotateY(-270deg);
        opacity: 1;
        animation-timing-function: linear;
    }


    100% {
        transform: rotateY(-360deg);
        opacity: 1;
        animation-timing-function: linear;
    }
}

.gen-animation {
    animation: 4.0s linear 0s infinite normal forwards running gen-animation-keyframes;
}